var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"content-view-wrapper pd-10",attrs:{"element-loading-background":_vm.loadingBackground}},[_c('div',{staticClass:"content-view"},[_c('section',{staticClass:"edit-wrapper"},[_c('div',{staticClass:"edit-wrapper__body"},[[_c('div',{staticClass:"primaryTitle"},[_vm._v("对接服务单")]),_vm._v(" "),_c('div',{staticClass:"baseInfo-box"},[_c('div',{staticClass:"edit-title"},[_vm._v("基本信息")]),_vm._v(" "),_c('el-form',{ref:"baseInfoForm",staticClass:"editForm",attrs:{"label-position":"right","inline":true,"label-width":"120px","rules":_vm.rules,"model":_vm.baseInfoForm}},[_c('el-form-item',{attrs:{"label":"客户名称","prop":"corpId"}},[_c('e6-vr-select',{attrs:{"data":_vm.corpList,"placeholder":"客户名称","title":"客户名称","clearable":"","virtual":"","remote":"","is-title":true,"props":{
                    id: 'corpId',
                    label: 'corpName'
                  }},on:{"filterChange":_vm.handleLoadCorpFilter,"change":_vm.handleChange},model:{value:(_vm.baseInfoForm.corpId),callback:function ($$v) {_vm.$set(_vm.baseInfoForm, "corpId", $$v)},expression:"baseInfoForm.corpId"}})],1),_vm._v(" "),_c('el-form-item',{attrs:{"label":"指派类型","prop":"solueIsDept"}},[_c('el-radio-group',{model:{value:(_vm.baseInfoForm.solueIsDept),callback:function ($$v) {_vm.$set(_vm.baseInfoForm, "solueIsDept", $$v)},expression:"baseInfoForm.solueIsDept"}},[_c('el-radio',{attrs:{"label":1}},[_vm._v("部门")]),_vm._v(" "),_c('el-radio',{attrs:{"label":0}},[_vm._v("员工")])],1)],1),_vm._v(" "),(_vm.baseInfoForm.solueIsDept == 1)?_c('el-form-item',{attrs:{"label":"指派部门","prop":"departmentId"}},[_c('e6-select',{ref:"t_select",attrs:{"dataList":_vm.treeData,"filterable":true,"slotTemplate":'tree',"multiple":false,"issingleleaf":false},model:{value:(_vm.baseInfoForm.departmentId),callback:function ($$v) {_vm.$set(_vm.baseInfoForm, "departmentId", $$v)},expression:"baseInfoForm.departmentId"}})],1):_c('el-form-item',{attrs:{"label":"指派处理人","prop":"solveRpid"}},[_c('all-user-search',{attrs:{"clear":"","placeholder":"指派处理人","title":"指派处理人","propsConfig":{
                    id: 'employeeId',
                    label: 'userName'
                  }},model:{value:(_vm.baseInfoForm.solveRpid),callback:function ($$v) {_vm.$set(_vm.baseInfoForm, "solveRpid", $$v)},expression:"baseInfoForm.solveRpid"}})],1),_vm._v(" "),_c('el-form-item',{attrs:{"label":"联系人","prop":"linkmanrpid"}},[_c('div',{staticClass:"select-content"},[_c('e6-vr-select',{attrs:{"data":_vm.contactList,"placeholder":"联系人","title":"","props":{
                      id: 'contactId',
                      label: 'contactName'
                    },"clearable":""},on:{"change":_vm.handleContact,"clear":_vm.handleClear},model:{value:(_vm.baseInfoForm.linkmanrpid),callback:function ($$v) {_vm.$set(_vm.baseInfoForm, "linkmanrpid", $$v)},expression:"baseInfoForm.linkmanrpid"}}),_vm._v(" "),_c('el-button',{staticStyle:{"margin-left":"10px"},attrs:{"type":"text"},on:{"click":_vm.addConcat}},[_vm._v("\n                    添加联系人\n                  ")])],1)]),_vm._v(" "),_c('el-form-item',{attrs:{"label":"联系电话","prop":"contactPhone"}},[_c('el-input',{attrs:{"placeholder":"联系电话"},model:{value:(_vm.baseInfoForm.contactPhone),callback:function ($$v) {_vm.$set(_vm.baseInfoForm, "contactPhone", $$v)},expression:"baseInfoForm.contactPhone"}})],1),_vm._v(" "),_c('el-form-item',{attrs:{"label":"要求完成时间","prop":"expecttime"}},[_c('el-date-picker',{attrs:{"type":"datetime","placeholder":"选择日期","value-format":"timestamp","picker-options":_vm.pickerOptions,"popper-class":"special"},model:{value:(_vm.baseInfoForm.expecttime),callback:function ($$v) {_vm.$set(_vm.baseInfoForm, "expecttime", $$v)},expression:"baseInfoForm.expecttime"}})],1),_vm._v(" "),_c('el-form-item',{attrs:{"label":"联系地址","prop":"defaultCity"}},[_c('e6-vr-select',{staticStyle:{"width":"300px"},attrs:{"data":_vm.addressList,"placeholder":"联系地址","title":"联系地址","virtual":"","clearable":"","is-title":true,"remote":"","props":{
                    id: 'fullName',
                    label: 'fullName'
                  }},on:{"filterChange":_vm.handleLoadAddressFilter,"change":_vm.handleCitySelect},model:{value:(_vm.baseInfoForm.defaultCity),callback:function ($$v) {_vm.$set(_vm.baseInfoForm, "defaultCity", $$v)},expression:"baseInfoForm.defaultCity"}})],1),_vm._v(" "),_c('el-form-item',{attrs:{"label":"详细地址","prop":"insAddress"}},[_c('el-input',{attrs:{"placeholder":"详细地址"},model:{value:(_vm.baseInfoForm.insAddress),callback:function ($$v) {_vm.$set(_vm.baseInfoForm, "insAddress", $$v)},expression:"baseInfoForm.insAddress"}})],1),_vm._v(" "),_c('el-form-item',{attrs:{"label":"备注","prop":"remark"}},[_c('el-input',{staticStyle:{"width":"500px"},attrs:{"placeholder":"请输入备注","type":"textarea","maxlength":"100"},model:{value:(_vm.baseInfoForm.remark),callback:function ($$v) {_vm.$set(_vm.baseInfoForm, "remark", $$v)},expression:"baseInfoForm.remark"}})],1)],1)],1)],_vm._v(" "),[_c('div',{staticClass:"edit-title"},[_vm._v("任务项设置")]),_vm._v(" "),_c('div',{staticClass:"edit-content",staticStyle:{"margin-bottom":"20px"}},[_c('div',{staticClass:"associated-info"},[_c('div',{staticClass:"right-button"},[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.addScene}},[_vm._v("\n                  添加任务项\n                ")]),_vm._v(" "),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.handleDelete}},[_vm._v("\n                  删除任务项\n                ")])],1)]),_vm._v(" "),_c('el-table',{attrs:{"data":_vm.tableData,"highlight-current-row":""},on:{"selection-change":_vm.handleSelectionChange}},[_c('el-table-column',{attrs:{"type":"selection","width":"50","align":"center"}}),_vm._v(" "),_vm._l((_vm.columnData),function(column,index){return _c('el-table-column',{key:index,attrs:{"show-overflow-tooltip":"","prop":column.fieldName,"label":column.fieldLabel,"min-width":column.width,"fixed":column.fixed,"align":column.align,"header-align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var row = ref.row;
return [(column.fieldName === 'dockName')?_c('div',[_c('e6-vr-select',{staticStyle:{"width":"220px"},attrs:{"data":_vm.dockList,"placeholder":"对接平台","title":"","clearable":"","multiple":""},on:{"change":function($event){return _vm.handleDock(row)}},model:{value:(row.dockIds),callback:function ($$v) {_vm.$set(row, "dockIds", $$v)},expression:"row.dockIds"}})],1):_c('span',[_vm._v(_vm._s(row[column.fieldName]))])]}}],null,true)})})],2)],1)]],2),_vm._v(" "),_c('div',{staticClass:"edit-wrapper__footer"},[_c('el-button',{staticClass:"cancel",on:{"click":_vm.handleGoBack}},[_vm._v("取消")]),_vm._v(" "),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.submit}},[_vm._v("确定")])],1)])]),_vm._v(" "),_c('add-concat-dialog',{attrs:{"addContactVisible":_vm.addContactVisible,"corpName":_vm.baseInfoForm.corpName,"corpId":_vm.baseInfoForm.corpId,"isNeedReq":true},on:{"handleClose":_vm.handleClose,"updateList":_vm.updateList}}),_vm._v(" "),_c('select-scene-type',{attrs:{"corpId":_vm.baseInfoForm.corpId,"selectSceneDialog":_vm.selectSceneDialog},on:{"handleClose":_vm.handleClose,"confirmSelect":_vm.confirmSelect}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }