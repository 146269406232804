<!--
 * @Description: 对接服务单
 * @Author: ChenXueLin
 * @Date: 2021-11-23 16:16:19
 * @LastEditTime: 2022-06-22 10:33:05
 * @LastEditors: ChenXueLin
-->
<template>
  <div
    class="content-view-wrapper pd-10"
    v-loading="loading"
    :element-loading-background="loadingBackground"
  >
    <div class="content-view">
      <section class="edit-wrapper">
        <div class="edit-wrapper__body">
          <!-- 基本信息 start -->
          <template>
            <div class="primaryTitle">对接服务单</div>
            <div class="baseInfo-box">
              <div class="edit-title">基本信息</div>
              <el-form
                ref="baseInfoForm"
                class="editForm"
                label-position="right"
                :inline="true"
                label-width="120px"
                :rules="rules"
                :model="baseInfoForm"
              >
                <el-form-item label="客户名称" prop="corpId">
                  <e6-vr-select
                    v-model="baseInfoForm.corpId"
                    :data="corpList"
                    placeholder="客户名称"
                    title="客户名称"
                    clearable
                    virtual
                    remote
                    :is-title="true"
                    @filterChange="handleLoadCorpFilter"
                    @change="handleChange"
                    :props="{
                      id: 'corpId',
                      label: 'corpName'
                    }"
                  ></e6-vr-select>
                </el-form-item>
                <el-form-item label="指派类型" prop="solueIsDept">
                  <el-radio-group v-model="baseInfoForm.solueIsDept">
                    <el-radio :label="1">部门</el-radio>
                    <el-radio :label="0">员工</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item
                  label="指派部门"
                  prop="departmentId"
                  v-if="baseInfoForm.solueIsDept == 1"
                >
                  <!-- 部门下拉框 -->
                  <e6-select
                    v-model="baseInfoForm.departmentId"
                    :dataList="treeData"
                    :filterable="true"
                    :slotTemplate="'tree'"
                    :multiple="false"
                    :issingleleaf="false"
                    ref="t_select"
                  ></e6-select>
                </el-form-item>
                <el-form-item label="指派处理人" prop="solveRpid" v-else>
                  <all-user-search
                    v-model="baseInfoForm.solveRpid"
                    clear
                    placeholder="指派处理人"
                    title="指派处理人"
                    :propsConfig="{
                      id: 'employeeId',
                      label: 'userName'
                    }"
                  >
                  </all-user-search>
                </el-form-item>
                <el-form-item label="联系人" prop="linkmanrpid">
                  <div class="select-content">
                    <e6-vr-select
                      v-model="baseInfoForm.linkmanrpid"
                      :data="contactList"
                      placeholder="联系人"
                      title=""
                      :props="{
                        id: 'contactId',
                        label: 'contactName'
                      }"
                      clearable
                      @change="handleContact"
                      @clear="handleClear"
                    ></e6-vr-select>
                    <el-button
                      type="text"
                      style="margin-left: 10px"
                      @click="addConcat"
                    >
                      添加联系人
                    </el-button>
                  </div>
                </el-form-item>
                <el-form-item label="联系电话" prop="contactPhone">
                  <el-input
                    placeholder="联系电话"
                    v-model="baseInfoForm.contactPhone"
                  ></el-input>
                </el-form-item>

                <el-form-item label="要求完成时间" prop="expecttime">
                  <el-date-picker
                    v-model="baseInfoForm.expecttime"
                    type="datetime"
                    placeholder="选择日期"
                    value-format="timestamp"
                    :picker-options="pickerOptions"
                    popper-class="special"
                  ></el-date-picker>
                </el-form-item>
                <el-form-item label="联系地址" prop="defaultCity">
                  <e6-vr-select
                    style="width:300px"
                    v-model="baseInfoForm.defaultCity"
                    :data="addressList"
                    placeholder="联系地址"
                    title="联系地址"
                    virtual
                    clearable
                    :is-title="true"
                    remote
                    @filterChange="handleLoadAddressFilter"
                    @change="handleCitySelect"
                    :props="{
                      id: 'fullName',
                      label: 'fullName'
                    }"
                  ></e6-vr-select>
                </el-form-item>
                <el-form-item label="详细地址" prop="insAddress">
                  <el-input
                    placeholder="详细地址"
                    v-model="baseInfoForm.insAddress"
                  ></el-input>
                </el-form-item>
                <el-form-item label="备注" prop="remark">
                  <el-input
                    v-model="baseInfoForm.remark"
                    placeholder="请输入备注"
                    type="textarea"
                    style="width: 500px"
                    maxlength="100"
                  ></el-input>
                </el-form-item>
              </el-form>
            </div>
          </template>
          <!-- 基本信息 end -->
          <!-- 任务项设置   start -->
          <template>
            <div class="edit-title">任务项设置</div>
            <div class="edit-content" style="margin-bottom: 20px">
              <div class="associated-info">
                <div class="right-button">
                  <el-button type="primary" @click="addScene">
                    添加任务项
                  </el-button>
                  <el-button type="primary" @click="handleDelete">
                    删除任务项
                  </el-button>

                  <!-- <el-button type="primary">
                    导入任务项
                  </el-button> -->
                </div>
              </div>
              <el-table
                :data="tableData"
                highlight-current-row
                @selection-change="handleSelectionChange"
              >
                <el-table-column
                  type="selection"
                  width="50"
                  align="center"
                ></el-table-column>
                <el-table-column
                  show-overflow-tooltip
                  v-for="(column, index) in columnData"
                  :key="index"
                  :prop="column.fieldName"
                  :label="column.fieldLabel"
                  :min-width="column.width"
                  :fixed="column.fixed"
                  :align="column.align"
                  header-align="center"
                >
                  <template slot-scope="{ row }">
                    <div v-if="column.fieldName === 'dockName'">
                      <e6-vr-select
                        v-model="row.dockIds"
                        @change="handleDock(row)"
                        :data="dockList"
                        placeholder="对接平台"
                        title=""
                        clearable
                        multiple
                        style="width:220px"
                      ></e6-vr-select>
                    </div>
                    <span v-else>{{ row[column.fieldName] }}</span>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </template>
          <!-- 任务项设置   end -->
        </div>
        <div class="edit-wrapper__footer">
          <el-button class="cancel" @click="handleGoBack">取消</el-button>
          <el-button type="primary" @click="submit">确定</el-button>
        </div>
      </section>
    </div>
    <!-- 添加联系人弹框 -->
    <add-concat-dialog
      :addContactVisible="addContactVisible"
      @handleClose="handleClose"
      :corpName="baseInfoForm.corpName"
      :corpId="baseInfoForm.corpId"
      @updateList="updateList"
      :isNeedReq="true"
    ></add-concat-dialog>
    <!-- 添加任务项弹框 -->
    <select-scene-type
      :corpId="baseInfoForm.corpId"
      @handleClose="handleClose"
      @confirmSelect="confirmSelect"
      :selectSceneDialog="selectSceneDialog"
    ></select-scene-type>
  </div>
</template>

<script>
import base from "@/mixins/base";
import { addDockService } from "@/api";
import AddConcatDialog from "@/components/addConcatDialog.vue";
import { printError } from "@/utils/util";
import addTask from "@/mixins/addTask";
import addService from "@/mixins/addService";
import SelectSceneType from "@/components/workOrderManage/selectSceneType.vue";
import allUserSearch from "@/components/allUserSearch";
export default {
  name: "dockingService",
  components: { AddConcatDialog, SelectSceneType, allUserSearch },
  data() {
    return {
      baseInfoForm: {
        corpId: "",
        linkmanrpid: "",
        solueIsDept: 1, //指派类型1部门0员工
        departmentId: [], //指派部门
        solveRpid: "", //指派处理人，
        insAddress: "", //详细地址
        contactPhone: "", //电话
        defaultCity: "", //默认选中地址
        expecttime: "",
        remark: ""
      }, //基本信息
      columnData: [
        {
          fieldName: "sceneName",
          display: true,
          fieldLabel: "场景名称",
          width: 200,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "sceneTypeName",
          display: true,
          fieldLabel: "场景类型",
          width: 150,
          disabled: false,
          fixed: false,
          align: "center"
        },

        {
          fieldName: "dockName",
          display: true,
          fieldLabel: "对接平台",
          width: 200,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ], //表头
      tableData: [],
      dockList: [
        { id: 1, label: "现代牧业" },
        { id: 2, label: "珠海城管局809对接" },
        { id: 3, label: "美特好对接公安局" },
        { id: 4, label: "武汉运管809对接" },
        { id: 5, label: "广州市白云区809对接" },
        { id: 6, label: "捷依808对接" },
        { id: 7, label: "杭州交警支队平台GPS数据对接" },
        { id: 8, label: "中石油808对接" },
        { id: 9, label: "天地华宇808对接" },
        { id: 10, label: "车云通车辆卫星监控云平台809对接" },
        { id: 11, label: "深圳市城管局808对接" },
        { id: 12, label: "邯郸市东特网络科技808对接" },
        { id: 13, label: "启明信息技术808对接" },
        { id: 14, label: "镇海区建筑渣土监控管理平台809对接" },
        { id: 15, label: "宁夏道路运输综合监管与服务平台809对接" },
        { id: 16, label: "广州809对接" },
        { id: 17, label: "深圳市城管局809对接" },
        { id: 18, label: "江西宜春809对接" },
        { id: 19, label: "石家庄绿配平台808对接" },
        { id: 20, label: "北京市汽车租赁行业管理与服务信息系统809对接" },
        { id: 21, label: "深圳市环卫处808对接" },
        { id: 22, label: "云南楚雄州809对接" },
        { id: 23, label: "南京冷链平台对接-冷库" },
        { id: 24, label: "南京冷链平台对接-冷柜" },
        { id: 25, label: "南京冷链平台对接-冷藏车" },
        { id: 26, label: "奥喜埃809对接" },
        { id: 27, label: "汇通809对接" },
        { id: 28, label: "广西亚加808对接" },
        { id: 29, label: "宁波渣土办809对接" },
        { id: 30, label: "青州808对接" },
        { id: 31, label: "宁夏809对接" },
        { id: 32, label: "宁波镇海809对接" },
        { id: 33, label: "中铁铁龙809对接" },
        { id: 34, label: "宁波象山重点车辆监管平台809对接" },
        { id: 35, label: "宁夏哈纳斯809对接" },
        { id: 36, label: "银川交通运输安全监管平台809对接" },
        { id: 37, label: "百世809对接" },
        { id: 38, label: "广东移动808对接" },
        { id: 39, label: "广西柳州危货运输监管平台809对接" },
        { id: 40, label: "湛江809对接" },
        { id: 41, label: "广州市交通管理总站809对接" },
        { id: 42, label: "镇江楚州808对接" },
        { id: 43, label: "公路研究院冷链平台809对接" },
        { id: 44, label: "甘肃809对接" },
        { id: 45, label: "湖北809对接" },
        { id: 46, label: "徐圩新区危化品车辆道路运输管控平台809对接" },
        { id: 47, label: "广州城管局808对接" },
        { id: 48, label: "四川809对接" },
        { id: 49, label: "补传数据809对接" },
        { id: 50, label: "壹米滴答808对接" },
        { id: 51, label: "四川遂宁809对接" },
        { id: 52, label: "福建远望808对接" },
        { id: 53, label: "宁波海关白卡系统808对接" },
        {
          id: 54,
          label: "浙江省重点营运车辆联网联控系统企业动态监控管理数据对接"
        },
        { id: 55, label: "一车多终端专用对接" },
        { id: 56, label: "国脉809对接" },
        { id: 57, label: "云南两客一危809对接" },
        { id: 58, label: "云水集团809对接" },
        { id: 59, label: "阜南城管局809对接" },
        { id: 60, label: "江门市建筑垃圾平台808对接" },
        { id: 61, label: "华润水泥809对接" },
        { id: 62, label: "浙江普货809对接" },
        { id: 63, label: "河源809对接" },
        { id: 64, label: "河源交警809对接" },
        { id: 65, label: "江门809对接" },
        { id: 66, label: "广西809对接" },
        { id: 67, label: "东风日梱808对接" },
        { id: 68, label: "金坤809对接" },
        { id: 69, label: "云南车承云水集团809对接" },
        { id: 70, label: "宁波809对接" },
        { id: 71, label: "阜阳盛航第三方平台对接" },
        { id: 72, label: "茂名危化809对接" },
        { id: 73, label: "云南车乘809对接" },
        { id: 74, label: "云南普货809对接" },
        { id: 75, label: "易流云转V5" },
        { id: 76, label: "深圳市生活垃圾全过程管理平台809对接" },
        { id: 77, label: "跨越铸剑平台808对接" },
        { id: 78, label: "阜阳航盛第三方平台对接" },
        { id: 79, label: "邢台威县809对接" },
        { id: 80, label: "宁波危化品809对接" },
        { id: 81, label: "厦门海关808对接" },
        { id: 82, label: "杭州普货809对接" },
        { id: 83, label: "恒慧809对接" },
        { id: 84, label: "韶关力保808对接" },
        { id: 85, label: "国新809对接" },
        { id: 86, label: "宁波北仑区809对接" },
        { id: 87, label: "广东中外运809对接" },
        { id: 88, label: "深分类智慧管理平台808对接" },
        { id: 89, label: "广东北斗一航科技808对接" },
        { id: 90, label: "中山809对接" },
        { id: 91, label: "揭阳809对接" },
        { id: 92, label: "京东冷链平台对接" },
        { id: 93, label: "京东普货平台对接" },
        { id: 94, label: "顺丰对接" },
        { id: 95, label: "广西中烟808对接" },
        { id: 96, label: "鹰潭第三方监控平台809对接" },
        { id: 97, label: "星软809对接" },
        { id: 98, label: "鹰潭市余江区第三方监控平台809对接" },
        { id: 99, label: "佛山环卫808对接" },
        { id: 100, label: "路上智驾太保809对接" },
        { id: 101, label: "太保809对接" },
        { id: 102, label: "广西柳州809对接" },
        { id: 103, label: "广州花都区809对接" },
        { id: 104, label: "中外运股份808对接" },
        { id: 105, label: "牧运通808对接" },
        { id: 106, label: "浙江两客一危809对接" },
        { id: 107, label: "杭州两客一危809对接" },
        { id: 108, label: "陕西两客一危809对接" },
        { id: 109, label: "云链智塔809对接" },
        { id: 110, label: "运途云主动安全809对接" },
        { id: 111, label: "江苏809对接" },
        { id: 112, label: "安徽智能安全管理服务平台809对接" },
        { id: 113, label: "中石化LNG接收站物流监管平台809对接" },
        { id: 114, label: "河北809对接" },
        { id: 115, label: "洛阳809对接" },
        { id: 116, label: "河南809对接" },
        { id: 117, label: "烟台普货809对接" },
        { id: 118, label: "潮州809对接" },
        { id: 119, label: "新疆809对接" },
        { id: 120, label: "东莞海心沙浓缩液监控平台809对接" },
        { id: 121, label: "上海城配平台809对接" },
        { id: 122, label: "山东中烟对接" },
        { id: 123, label: "中联物流对接" },
        { id: 124, label: "永辉809对接" },
        { id: 125, label: "深圳智慧环保平台固体废物智慧监管系统808对接" },
        { id: 126, label: "新奥能源808对接" },
        { id: 127, label: "杭州交警809对接" },
        { id: 128, label: "天津809对接" },
        { id: 129, label: "南宁809对接" },
        { id: 130, label: "宁波港809对接" },
        { id: 131, label: "云南文山州809对接" },
        { id: 132, label: "福州汉纳森808对接" },
        { id: 133, label: "河北第三方平台809对接" },
        { id: 134, label: "唐山第三方平台809对接" },
        { id: 135, label: "汇通String协议对接" },
        { id: 136, label: "菜鸟对接-金融街" },
        { id: 137, label: "菜鸟对接-丹鸟" },
        { id: 138, label: "菜鸟对接-1688" },
        { id: 139, label: "江西抚州809对接" },
        { id: 140, label: "北京节能减排监测平台808对接" },
        { id: 141, label: "北京节能减排监测平台新能源车808对接" },
        { id: 142, label: "汇通808对接" },
        { id: 143, label: "安徽809对接" },
        { id: 144, label: "东莞809对接" },
        { id: 145, label: "南充第三方平台809对接" },
        { id: 146, label: "邹平809对接" },
        { id: 147, label: "重庆九龙坡第三方平台809对接" },
        { id: 148, label: "辽宁盘锦交警809对接" }
      ], //对接平台下拉框
      selectColumn: [], //选中的数据
      addContactVisible: false //添加联系人弹框
    };
  },
  mixins: [base, addTask, addService],
  methods: {
    //客户下拉框处理逻辑
    handleLoadCorpFilter: _.debounce(async function(val) {
      if (val) {
        this.baseInfoForm.corpId = "";
        this.loadCorpData(val);
      }
    }, 300),
    //点击确定
    submit() {
      this.$refs.baseInfoForm.validate(valid => {
        if (valid) {
          if (!this.tableData.length) {
            this.$message.warning("请至少选择一个任务项");
            return;
          }
          this.baseInfoForm.contactName = this.filterSelect(
            this.contactList,
            "contactId",
            this.baseInfoForm.linkmanrpid
          ).contactName;
          this.baseInfoForm.corpName = this.filterSelect(
            this.corpList,
            "corpId",
            this.baseInfoForm.corpId
          ).corpName;
          this.addDockServiceReq();
        }
      });
    },
    //创建对接服务单请求
    async addDockServiceReq() {
      try {
        this.loading = true;
        let res = await addDockService({
          ...this.baseInfoForm,
          solveRpid:
            this.baseInfoForm.solueIsDept == 1
              ? this.baseInfoForm.departmentId.join("")
              : this.baseInfoForm.solveRpid,
          taskItems: this.tableData
        });
        if (res.code == "OK") {
          this.$message.success("创建成功");
          this.closeTag(this.$route);
          this.routerPush({
            name: "workOrderManage/serviceListManage",
            params: {
              refresh: true
            }
          });
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },

    //表格选中数据
    handleSelectionChange(val) {
      this.selectColumn = val;
    },
    //删除任务项
    handleDelete() {
      if (!this.selectColumn.length) {
        this.$message.warning("请先勾选数据");
        return;
      }
      let tableData = _.cloneDeep(this.tableData);
      let selectColumn = _.cloneDeep(this.selectColumn);
      this.tableData = tableData.filter(v =>
        selectColumn.every(n => {
          return !(n.sceneId == v.sceneId);
        })
      );
    },
    handleDock(row) {
      var result = this.dockList.filter(function(v) {
        return row.dockIds.indexOf(v.id) !== -1; // 利用filter方法来遍历是否有相同的元素
      });
      row.dockName = result
        .map(item => {
          return item.label;
        })
        .join(",");
    },
    // 关闭弹框
    handleClose(visibleName) {
      this[visibleName] = false;
    }
  }
};
</script>
<style lang="scss" scoped>
.edit-content {
  padding: 20px 20px 2px;
  .e6-vr-select {
    width: 100%;
  }
  .el-table {
    & tr th > .cell {
      height: 38px;
      line-height: 38px;
      color: #898c91;
      background-color: #ffffff;
    }
    .el-table__body .el-table__row:nth-child(2n) td {
      background-color: #ffffff;
    }
  }
}

.form-content {
  .el-input {
    width: 280px;
  }
}

.edit-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 15px;
  .operate-action {
    height: 38px;
  }
}

.associated-info {
  display: flex;
  margin-bottom: 20px;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
}
.table-input {
  width: 300px;
}
/deep/.dialog-box .el-dialog__body {
  padding: 20px;
  box-sizing: border-box;
}
/deep/.edit-content .table-select {
  width: 200px;
}
</style>
